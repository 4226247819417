export const RestComponent = {
    data: () => ({
        entryData: null,
        isLoading: false
    }),

    methods: {
        navigate(to, animation, mode){
            this.isLoading = true
            this.$store.commit('app/setTransitionName', animation)
            this.$store.commit('app/setTransitionMode', mode)

            this.$nextTick(function() {
                this.$router.push({ name: to })
            });
        },

        navigateAni(delay, to, animation, mode){
            this.isLoading = true
            this.$store.commit('app/setTransitionName', animation)
            this.$store.commit('app/setTransitionMode', mode)

            this.$emit('navigating');
            setTimeout(() => this.$router.push({ name: to }), delay);
        },
        navigateGo(steps, animation, mode){
            this.$store.commit('app/setTransitionName', animation)
            this.$store.commit('app/setTransitionMode', mode)
            this.$nextTick(function() {
                this.$router.go(steps)
            });
        },
    },
    created() {
        this.$store.commit('app/setTransitionName', 'fade')
        this.isLoading = false
    },
}
