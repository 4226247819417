export const ViewHeight = {
    data: () => ({

    }),

    methods: {
        setViewHeight(){
            let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    },
}
