import { createStore } from 'vuex'
import app from './modules/app'
import entry from './modules/entry'

const store = createStore({
    modules: {
        app,
        entry,
    },
})

export default store
