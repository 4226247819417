const state = () => ({
    companyFilter: [],
    companySearch: '',
    companySort: 'title ASC',
})

// Getters
const getters = {
}

// Actions
const actions = {
}

// Mutations
const mutations = {
    setCompanyFilter(state, value){
        state.companyFilter = value
    },
    setCompanySearch(state, value){
        state.companySearch = value
    },
    setCompanySort(state, value){
        state.companySort = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
