import { createRouter, createWebHistory, useRoute } from 'vue-router'
import { formatRoutes } from './utils/format-routes';
import store from './store'

let routes = [];


const router = createRouter({
  history: createWebHistory(),
  routes
})

// Generate dynamic routes
const craftRoutes = formatRoutes(JSON.parse(window.siteData.routes));
craftRoutes.forEach(function(route){
    router.addRoute(route);
})

// Auth routes
router.beforeEach((to, from, next) => {
        next()
})
export default router
