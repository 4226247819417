export const formatRoutes = ( routes = [] ) => {

	return routes.map( route => {

		const entryId = route.id;
		let headTitle = route.title + ' - Business areas';

		if(route.metatitle){
			headTitle = route.metatitle + ' - Business areas'
		}

        return {
            path: route.slug,
            component: () => import(/* webpackChunkName: "Vue" */ '@/js/templates/' + route.pagetype + '.vue'),
            name: route.name,
            props: true,
            meta: {
                entryId,
                endpoint: route.endpoint,
                title: headTitle,
				metadescription: route.metadescription,
				crumbs: route.crumbs ? route.crumbs : [],
				workflows: route.workflows ? route.workflows : false,
            },
        };

	})
		// Remove faulty routes
		.filter( route => route !== null );
};
