const state = () => ({
    scrollPosition: 0,
    csrfToken: '',
    isMapVisible: false,
    siteUrl: '',
    routes: [],
    entryData: [],
    transitionName: '',
    transitionMode: '',
})

// Getters
const getters = {
    scrollPosition: (state, getters) => {
        return state.scrollPosition
    }
}

// Actions
const actions = {
}

// Mutations
const mutations = {
    setSiteUrl(state, value) {
        state.siteUrl = value
    },
    setCsrfToken(state, token){
        state.csrfToken = token
    },
    setRoutes(state, value) {
        state.routes = value
    },
    setEntryData(state, value) {
        state.entryData = value
    },
    setTransitionName(state, value){
        state.transitionName = value
    },
    setTransitionMode(state, value){
        state.transitionMode = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
