// Import
import { createApp, defineAsyncComponent, defineComponent } from "vue"
import router from './router'
import store from './store'
import { mapState, mapGetters } from 'vuex'

import VueClickAway from "vue3-click-away"

import { RestComponent } from './mixins/rest-component'
import { ViewHeight } from './mixins/view-height'

// Declare
declare const window: any;

// App main
const main = async () => {
    const app = createApp({
        delimiters: ['${', '}'],
        data: () => ({
        }),
        methods: {
        },
        computed:{
            ...mapState({
                transitionName: (state) => state.app.transitionName,
                transitionMode: (state) => state.app.transitionMode,
				companyFilter: (state) => state.entry.companyFilter,
				companySearch: (state) => state.entry.companySearch,
            }),
            ...mapGetters({

            })
        },
		watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    document.title = to.meta.title || 'Business areas';
                    document.querySelector('meta[name="description"]').setAttribute("content", to.meta.metadescription || 'Esbjerg havn business areas');
                }
            },
        },
        beforeMount(){
			store.commit('app/setSiteUrl', window.siteData.siteUrl)
            store.commit('app/setCsrfToken', window.siteData.csrf)
            store.commit('app/setRoutes', window.siteData.routes)
            store.commit('app/setEntryData', window.siteData.entryData)
        },
    });

    //Use
	app.use(VueClickAway)
    app.use(router);
    app.use(store);

	//Mixin
	app.mixin(RestComponent)
	app.mixin(ViewHeight)

    // Mount the app
    const vm = app.mount('#app');
    return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
